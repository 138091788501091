import { useEffect, useRef, useState } from "react"
import Link from "next/link"
import { Popover } from "@headlessui/react"
import clsx from "clsx"

function MenuIcon({ open, ...props }: any) {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d={open ? "M17 7 7 17M7 7l10 10" : "m15 16-3 3-3-3M15 8l-3-3-3 3"} />
    </svg>
  )
}

export function IntraPageNavBar(props: any) {
  let navBarRef = useRef()
  let [activeIndex, setActiveIndex] = useState(null)
  let mobileActiveIndex = activeIndex === null ? 0 : activeIndex
  let sections = props.sections

  useEffect(() => {
    function updateActiveIndex() {
      let newActiveIndex = null
      let elements = sections.map(({ id }: any) => document.getElementById(id))
      let bodyRect = document.body.getBoundingClientRect()
      // @ts-ignore
      let offset = bodyRect.top + navBarRef.current.offsetHeight + 1

      if (window.scrollY >= Math.floor(bodyRect.height) - window.innerHeight) {
        // @ts-ignore
        setActiveIndex(sections.length - 1)
        return
      }

      for (let index = 0; index < elements.length; index++) {
        if (window.scrollY >= elements[index].getBoundingClientRect().top - offset) {
          newActiveIndex = index
        } else {
          break
        }
      }

      // @ts-ignore
      setActiveIndex(newActiveIndex)
    }

    updateActiveIndex()

    window.addEventListener("resize", updateActiveIndex)
    window.addEventListener("scroll", updateActiveIndex, { passive: true })

    return () => {
      window.removeEventListener("resize", updateActiveIndex)
      // @ts-ignore
      window.removeEventListener("scroll", updateActiveIndex, { passive: true })
    }
  }, [])

  return (
    <div ref={navBarRef} {...props.attributes} className="sticky top-0 z-10">
      <Popover className="sm:hidden">
        {({ open }) => (
          <>
            <div
              className={clsx(
                "relative flex items-center px-4 py-3",
                !open &&
                  "bg-white/95 shadow-xs [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur-sm"
              )}
            >
              {!open && (
                <>
                  <span aria-hidden="true" className="font-mono text-sm text-blue-600">
                    {/* @ts-ignore */}
                    {(mobileActiveIndex + 1).toString().padStart(2, "0")}
                  </span>
                  <span className="ml-4 text-base font-medium text-slate-900">
                    {/* @ts-ignore */}
                    {sections[mobileActiveIndex].title}
                  </span>
                </>
              )}
              <Popover.Button
                className={clsx(
                  "-mr-1 ml-auto flex h-8 w-8 items-center justify-center",
                  open && "relative z-10"
                )}
                aria-label="Toggle navigation menu"
              >
                {!open && (
                  <>
                    {/* Increase hit area */}
                    <span className="absolute inset-0" />
                  </>
                )}
                <MenuIcon open={open} className="h-6 w-6 stroke-slate-700" />
              </Popover.Button>
            </div>
            <Popover.Panel className="absolute inset-x-0 top-0 bg-white/95 py-3.5 shadow-xs [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur-sm">
              {sections.map((section: any, sectionIndex: number) => (
                <Popover.Button
                  as={Link}
                  key={section.id}
                  href={`#${section.id}`}
                  className="flex items-center px-4 py-1.5"
                >
                  <span aria-hidden="true" className="font-mono text-sm text-stone-600">
                    {(sectionIndex + 1).toString().padStart(2, "0")}
                  </span>
                  <span className="ml-4 text-base font-medium text-slate-900">{section.title}</span>
                </Popover.Button>
              ))}
            </Popover.Panel>
            <div className="absolute inset-x-0 bottom-full z-10 h-4 bg-white" />
          </>
        )}
      </Popover>
      <div className="hidden sm:flex sm:h-32 sm:justify-center sm:border-b sm:border-slate-200 sm:bg-white/95 sm:[@supports(backdrop-filter:blur(0))]:bg-white/80 sm:[@supports(backdrop-filter:blur(0))]:backdrop-blur-sm">
        <ol
          role="list"
          className="mb-[-2px] grid auto-cols-[minmax(0,15rem)] grid-flow-col text-base font-medium text-slate-900 [counter-reset:section]"
        >
          {sections.map((section: any, sectionIndex: number) => (
            <li key={section.id} className="flex [counter-increment:section]">
              <Link
                href={`#${section.id}`}
                className={clsx(
                  "flex w-full flex-col items-center justify-center border-b-2 before:mb-2 before:font-mono before:text-sm before:content-[counter(section,decimal-leading-zero)]",
                  sectionIndex === activeIndex
                    ? "border-stone-600 bg-stone-50 text-stone-600 before:text-stone-600"
                    : "border-transparent before:text-slate-500 hover:bg-stone-50/40 hover:before:text-slate-900"
                )}
                legacyBehavior
              >
                {section.title}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
