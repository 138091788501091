/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline"
import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import {
  useAddItemToCart,
  useCheckoutUrl,
  useUpdateItemQuantity,
} from "@lib/swell/storefront-data-hooks"

export function PricingSectionsSingleWithFeaturesWithBuyButton(props) {
  const {
    sectionHeader,
    sectionHeaderPrimaryColor,
    sectionDescription,
    buttonText,
    buttonHref,
    featuresHeader,
    featuresSecondHeader,
    featuresDescription,
    features,
    product,
  } = props
  const [loading, setLoading] = useState(false)
  const addItem = useAddItemToCart()
  const updateItemQuantity = useUpdateItemQuantity()
  const checkoutUrl = useCheckoutUrl()
  const router = useRouter()
  const handleOnClick = async () => {
    setLoading(true)
    try {
      const cart = await addItem(product.data.id, 1)
      const item = cart.items.find((item) => item.product_id === product.data.id)
      updateItemQuantity(item.id, 1)
      router.push(checkoutUrl)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <>
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
              <span className="text-gray-900">{sectionHeader} </span>
              <span className="text-primary-600">{sectionHeaderPrimaryColor}</span>
            </h1>
            <p className="mt-5 text-xl text-gray-500">{sectionDescription}</p>
          </div>

          <button
            onClick={handleOnClick}
            disabled={loading}
            className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700 sm:mt-10 sm:w-auto xl:mt-0"
          >
            {buttonText}
          </button>
        </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <h2 className="text-primary-600 text-base font-semibold uppercase tracking-wide">
              {featuresHeader}
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900">{featuresSecondHeader}</p>
            <p className="mt-4 text-lg text-gray-500">{featuresDescription}</p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
            <ul role="list" className="divide-y divide-gray-200">
              {features.slice(0, 5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature.name} className="flex py-4 md:py-0 md:pb-4">
                    <CheckIcon className="h-6 w-6 shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                ) : (
                  <li key={feature.name} className="flex py-4">
                    <CheckIcon className="h-6 w-6 shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                )
              )}
            </ul>
            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
            >
              {features.slice(5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature.name} className="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                    <CheckIcon className="h-6 w-6 shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                ) : (
                  <li key={feature.name} className="flex py-4">
                    <CheckIcon className="h-6 w-6 shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
