import s from "./LoadingDots.module.css"

const LoadingDots = () => {
  return (
    <span className="inline-flex items-center text-center leading-7">
      <span className="mx-0.5 h-2 w-2 rounded-full bg-accents-6 animate-ping" />
      <span className="mx-0.5 h-2 w-2 rounded-full bg-accents-6 animate-ping [animation-delay:0.2s]" />
      <span className="mx-0.5 h-2 w-2 rounded-full bg-accents-6 animate-ping [animation-delay:0.4s]" />
    </span>
  )
}

export default LoadingDots
