import { useContext } from "react"
import { Context } from "../Context"

export function useRemoveItemFromCart() {
  const { swell, setCart } = useContext(Context)
  async function removeItemFromCart(itemId: number | string) {
    const response = await fetch("/api/cart/remove", {
      method: "POST",
      body: JSON.stringify({
        cart_id: localStorage.getItem("cartId"),
        itemId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Failed to remove item from cart")
    }

    const newCart = await response.json()
    localStorage.setItem("cartId", newCart.id)
    setCart(newCart)
    return newCart
  }

  return removeItemFromCart
}
