import { Builder, withChildren } from '@builder.io/react'
import { EyeCandy } from './EyeCandy'
const EyeCandyWithChildren = withChildren(EyeCandy)

Builder.registerComponent(EyeCandyWithChildren, {
  name: 'Eye candy',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
  inputs: [
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://images.unsplash.com/photo-1485160497022-3e09382fb310?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
    },
    {
      name: 'overlayClasses',
      type: 'string',
      defaultValue: 'h-1/3 bg-linear-to-b from-white',
    },
    {
      name: 'aspectWidth',
      type: 'string',
      enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
      defaultValue: '16',
    },
    {
      name: 'aspectHeight',
      type: 'string',
      enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
      defaultValue: '9',
    },
  ],
})
