import { ArrowRightIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react"

import { getProduct } from "@lib/swell/storefront-data-hooks/src/api/operations-swell"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import swell from "swell-js"

export function ProductList({ products, categories }) {
  const [productsList, setProductsList] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true)
      const promises = products
        .map((entry) => entry.product)
        .filter((handle) => typeof handle === "string")
        .map(async (handle) => {
          return await getProduct({ slug: handle })
        })
      const result = await Promise.all(promises)
      setProductsList(result)
      setLoading(false)
    }
    if (products) {
      getProducts()
    }
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true)
      const promises = categories
        .map((entry) => entry.category)
        .filter((handle) => typeof handle === "string")
        .map(async (handle) => {
          return await swell.categories.get({ slug: handle })
        })
      const result = await Promise.all(promises)
      setCategoriesList(result)
      setLoading(false)
    }
    if (categories) {
      getCategories()
    }
  }, [])

  return (
    <div className="bg-white py-16">
      <div className="mx-auto max-w-2xl sm:w-3/5 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            New Way Van Life Products
          </h2>
        </div>

        <p className="text-center text-lg py-8">
          With the Superstructure Panel Kit installed, your van options are endless. Check out some
          of our best sellers.
        </p>

        {/* Products */}
        <div className="grid grid-cols-2 gap-y-4 gap-x-4 sm:gap-y-8 lg:gap-x-6">
          {productsList?.map((product) => (
            <div
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 bg-white drop-shadow-2xl"
            >
              <div className="aspect-h-2 aspect-w-3">
                <img
                  alt={product.name}
                  src={product.images[0].src}
                  className="w-full bg-gray-200 object-cover group-hover:opacity-75"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-lg sm:text-2xl font-medium text-gray-900">
                  <a href={`product/${product.slug}`}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="font-mono text-md sm:text-xl font-medium text-gray-900">
                    {getPrice(product.price, product.currency)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr className="my-8" />

        {/* Categories */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-4 sm:gap-y-8 lg:gap-x-6">
          {categoriesList?.map((category) => (
            <div
              key={category.id}
              className="group relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 bg-white drop-shadow-2xl"
            >
              <img
                alt={category.results[0].name}
                src={category.results[0].images[0].file.url}
                className="w-full bg-gray-200 object-cover group-hover:opacity-75"
              />
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-lg sm:text-2xl font-medium text-gray-900">
                  <a href={`collection/${category.results[0].slug}`}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {category.results[0].name}
                  </a>
                </h3>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4 mt-16">
          <a
            href="shop"
            className="bg-black text-white px-4 py-2 inline-flex items-center gap-x-1.5 rounded-full text-lg font-semibold sm:text-base"
          >
            Shop Kits & Parts <ArrowRightIcon className="w-4 h-4 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}
