import { ArrowRightIcon } from "@heroicons/react/20/solid"
import { builder } from "@builder.io/react"
import { useState, useEffect } from "react"

export function BlogComponent({ postReferences }) {
  const [posts, setPosts] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true)
      const posts = await builder.getAll("blog-post", {
        // Include references, like our `author` ref
        options: { enrich: true },
        query: {
          id: { $in: postReferences.map((postReference) => postReference.post.id) },
        },
      })
      setPosts(posts)
      setLoading(false)
    }
    fetchPosts()
  }, [])

  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            New Way Van Life Blog
          </h2>
        </div>
        <div className="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts?.map((post) => {
            let date = new Date(post.data.date)
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
            }
            let dateString = date.toLocaleDateString("en-US", options)
            let dateTimeAttributeValue = date.toISOString()

            return (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-3xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 drop-shadow-2xl"
              >
                <img
                  alt=""
                  src={post.data.image}
                  className="absolute inset-0 -z-10 size-full object-cover"
                />
                <div className="absolute inset-0 -z-10 bg-linear-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                <h3 className="mt-3 text-xl/6 text-white">
                  <a href={`/blog/${post.data.handle}`}>
                    <span className="absolute inset-0" />
                    {post.data.title}
                  </a>
                </h3>
                <div className="font-mono flex-col gap-y-1 overflow-hidden text-md/6 text-gray-300 mt-4 flex gap-x-4">
                  <div className="flex gap-x-2.5">
                    {/* TODO: Use square images otherwise this is stretching them weirdly */}
                    <img
                      alt=""
                      src={post.data.author.value.data.image}
                      className="size-6 flex-none rounded-full bg-white/10 object-cover object-top"
                    />
                    {post.data.author.value.data.name}
                  </div>
                  <time dateTime={dateTimeAttributeValue} className="mr-8">
                    {dateString}
                  </time>
                </div>
              </article>
            )
          })}
        </div>
        <div className="flex justify-center space-x-4 mt-8">
          <a
            href="blog"
            className="bg-black text-white px-4 py-2 inline-flex items-center gap-x-1.5 rounded-full text-xs sm:text-base"
          >
            View Blog <ArrowRightIcon className="w-4 h-4 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}
