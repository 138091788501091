import { Builder } from '@builder.io/react'
import { CarouselTestimonials } from './CarouselTestimonials'

Builder.registerComponent(CarouselTestimonials, {
  name: 'Carousel Testimonials',
  inputs: [
    {
      name: 'testimonials',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'review',
          type: 'string',
          required: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
        },
      ],
      defaultValue: [
        {
          name: 'Jim',
          review: "“We absolutely love our van. We've never felt so free!”",
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F86c411b0ae7d404c8cdfbc782a799149%2Faf9e4f4fe31b4ac4a4f07356149d93e3',
        },
        {
          name: 'Brandon and Harrison',
          review:
            '“As a gay couple that LOOOVEs the outdoors, this van is a perfect fit for us and our lifestyle. So sleek and stylish too!”',
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F86c411b0ae7d404c8cdfbc782a799149%2F61fc302f732e477cb146c8a1916f94d3',
        },
        {
          name: 'Harmony and Dave',
          review:
            "“We love the simplicity of the Super Structure Panel Kit that allows us to build out our van the way we want on a budget! The dogs love sleeping in the warm van with us at night while we're on camping trips.”",
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F86c411b0ae7d404c8cdfbc782a799149%2F2c499b044e04430ba94bf40e2cd540fb',
        },
        // More testimonials...
      ],
    },
  ],
})
