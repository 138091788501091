import { Tab } from "@headlessui/react"
import { ArrowRightIcon } from "@heroicons/react/20/solid"

export function BuildHighlight({ tabs }) {
  return (
    <div className="bg-gray-100">
      <div className="p-8 max-w-5xl mx-auto">
        <div className="flex flex-col w-full text-center">
          <h2 className="mb-4 prose text-2xl sm:text-5xl leading-relaxed tracking-tight">
            Designed for customization.
          </h2>
          <h2 className="mb-4 prose text-2xl sm:text-5xl leading-relaxed tracking-tight">
            Built for adventure.
          </h2>
          <p className="text-gray-700 mb-8 text-sm sm:text-base font-mono tracking-tight leading-10">
            A modular design system that evolves as your journey takes new turns
          </p>
        </div>
        <Tab.Group>
          <Tab.List className="grid grid-cols-2 gap-4 justify-items-center sm:flex sm:justify-center sm:space-x-4 w-full max-w-4xl">
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  `p-4 rounded-lg drop-shadow-lg ${
                    selected ? "bg-black text-white" : "bg-white text-black"
                  }`
                }
              >
                <div className="flex flex-col items-center">
                  <img
                    src={tab.image}
                    alt={tab.title}
                    className="w-32 h-20 object-cover rounded-t-lg"
                  />
                  <h3 className="text-xs sm:text-lg font-semibold">{tab.title}</h3>
                  <p className="font-mono text-xs sm:text-base">{tab.price}</p>
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="sm:mt-4">
            {tabs.map((tab, index) => (
              <Tab.Panel key={index} className="text-center flex flex-col items-center">
                <div className="flex justify-center relative w-3/4 my-6">
                  <img
                    src={tab.centerImage}
                    alt="Center"
                    className=" h-full w-full object-contain object-center"
                  />
                </div>
                <div className="grid grid-cols-3 sm:grid-cols-6 gap-4 sm:gap-8 my-4">
                  {tab.features.map((feature, idx) => (
                    <div key={idx} className="flex flex-col items-center pr-2 sm:pr-4">
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-8 h-8 sm:w-24 sm:h-24 object-contain mb-2"
                      />
                      <div className="border-l border-gray-300">
                        <h3 className="ml-1 text-sm sm:text-lg font-mono">{feature.title}</h3>
                      </div>
                      <p className="text-gray-600 text-xs sm:text-base font-mono">
                        {feature.price}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center space-x-4">
                  <a
                    href={tab.link}
                    className="bg-white text-black border border-black px-4 py-2 rounded-full inline-flex items-center gap-x-1.5 text-xs sm:text-base"
                  >
                    Explore Van Spotlight <ArrowRightIcon className="w-4 h-4 stroke-black" />
                  </a>
                  <a
                    href={tab.link}
                    className="bg-black text-white px-4 py-2 inline-flex items-center gap-x-1.5 rounded-full text-xs sm:text-base sm:inline-flex"
                  >
                    Our Build Process <ArrowRightIcon className="w-4 h-4 stroke-white" />
                  </a>
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}
