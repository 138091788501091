import { useState, useEffect } from "react"
import { BuilderComponent, Builder, builder } from "@builder.io/react"
import builderConfig from "@config/builder"
import Link from "next/link"
import { useRouter } from "next/router"
import { LoadingDots } from "@components/ui"
import Image from "next/image"

Builder.isStatic = true

const postsPerPage = 30
function readingTimeMinutes(html: string) {
  const wpm = 225
  const words = html.trim().split(/\s+/).length
  return Math.ceil(words / wpm)
}

export function ThreeColumnBlogArticles({
  sectionTitle,
  description,
}: {
  sectionTitle: string
  description: string
}) {
  const router = useRouter()
  const initialPosts: string | any = "initial state"
  const [posts, setPosts] = useState(initialPosts)
  const [loading, setLoading] = useState(false)

  useEffect(() => setPosts(initialPosts), [initialPosts])

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true)
      const page = Number(router.query.page) || 0
      const posts = await builder.getAll("blog-post", {
        // Include references, like our `author` ref
        options: { enrich: true, noTargeting: true },
        query: { published: { $eq: "published" } },
        // For performance, don't pull the `blocks` (the full blog entry content)
        // when listing
        omit: "data.blocks",
        limit: postsPerPage,
        offset: page * postsPerPage,
      })

      setPosts(posts)
      setLoading(false)
    }
    if (typeof posts === "string") {
      fetchPosts()
    }
  }, [posts, router.query.page])

  if (!posts || typeof posts === "string" || loading) {
    return <LoadingDots />
  }

  return (
    <div>
      <div className="relative bg-gray-50 px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-24">
        <div className="absolute inset-0">
          <div className="h-1/3 bg-white sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {sectionTitle}
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">{description}</p>
          </div>
          <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((item: any, index: any) => {
              let date = new Date(item.data.date)
              const options: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
              let dateString = date.toLocaleDateString("en-US", options)
              let dateTimeAttributeValue = date.toISOString()
              return (
                <div
                  key={item.data.title}
                  className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                >
                  <div className="shrink-0">
                    <img className="h-48 w-full object-cover" src={item.data.image} alt="" />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-white p-6">
                    <div className="flex-1">
                      <p className="text-primary-600 text-sm font-medium">{item.data.category}</p>
                      <Link href={`/blog/${item.data.handle}`} passHref className="mt-2 block">
                        <div>
                          <p className="text-xl font-semibold text-gray-900">{item.data.title}</p>
                          <p className="mt-3 text-base text-gray-500">{item.data.blurb}</p>
                        </div>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="shrink-0">
                        <span className="sr-only">{item.data.author.value.data.name}</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={item.data.author.value.data.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          {item.data.author.value.data.name}
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={dateTimeAttributeValue}>{dateString}</time>
                          <span aria-hidden="true">&middot;</span>
                          <span>{readingTimeMinutes(item.data.content)} min read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
