import React from "react"
import { useEffect } from "react"
import { withBuilder } from "@builder.io/react"
import { classNames } from "core/utils"
import Script from "next/script"
export interface ButtonProps {
  attributes?: any
  text?: string
  formId: string
}

const ButtonComponent = ({ attributes, text, formId }: ButtonProps) => {
  const onClick = function () {
    // @ts-ignore
    window._klOnsite = window._klOnsite || []
    // @ts-ignore
    window._klOnsite.push(["openForm", `${formId}`])
  }
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          `klaviyoFormTrigger-${formId}`,
          "w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
        )}
        {...attributes}
      >
        {text}
      </button>
    </>
  )
}

export const KlaviyoFlyoutButton = withBuilder(ButtonComponent, {
  name: "Klaviyo flyout button",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13",
  inputs: [
    {
      name: "text",
      type: "text",
      defaultValue: "Click me!",
      bubble: true,
    },
    {
      name: "formId",
      type: "text",
      required: true,
      bubble: true,
    },
  ],
})
