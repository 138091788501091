import Image from "next/image"

export function ProductFeaturesWithFadingImage(props) {
  const { sectionTitle, sectionDescription, features, imageSrc, imageAlt } = props
  return (
    <>
      <div aria-hidden="true" className="relative">
        <Image
          src={imageSrc}
          alt={imageAlt}
          className="h-96 w-full object-cover object-center"
          fill={true}
        />
        <div className="absolute inset-0 bg-linear-to-t from-white" />
      </div>

      <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {sectionTitle}
          </h2>
          <p className="mt-4 text-gray-500">{sectionDescription}</p>
        </div>

        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
          {features &&
            features.map((feature, index) => (
              <div key={index} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
              </div>
            ))}
        </dl>
      </div>
    </>
  )
}
