import React from "react"
import Image from "next/image"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { StarIcon, ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid"

export function CarouselTestimonials({ testimonials }) {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Hear From Our Satisfied Customers
          </h2>
        </div>
      </div>
      <div>
        <CarouselProvider
          naturalSlideWidth={1}
          isIntrinsicHeight="true"
          totalSlides={3}
          infinite="true"
        >
          <ButtonBack className="absolute left-0 top-1/2 z-10 -translate-y-1/2 transform">
            <ChevronLeftIcon className="h-12 w-12 text-black" aria-hidden="true" />
          </ButtonBack>
          <Slider>
            {testimonials.map((testimonial, index) => (
              <Slide key={index}>
                <section className="bg-white px-6 py-8 lg:px-8">
                  <figure className="grid lg:grid-cols-2 grid-cols-1 mx-auto max-w-7xl">
                    <div className="col-span-1 p-4 mx-8">
                      <Image
                        layout="fill"
                        alt="test"
                        src={testimonial.image}
                        className="relative! rounded-3xl drop-shadow-lg"
                      />
                    </div>
                    <div className="col-span-1 p-4 mx-8">
                      <p className="sr-only">5 out of 5 stars</p>
                      <div className="flex gap-x-1 text-slate-900">
                        <StarIcon aria-hidden="true" className="size-5 flex-none" />
                        <StarIcon aria-hidden="true" className="size-5 flex-none" />
                        <StarIcon aria-hidden="true" className="size-5 flex-none" />
                        <StarIcon aria-hidden="true" className="size-5 flex-none" />
                        <StarIcon aria-hidden="true" className="size-5 flex-none" />
                      </div>
                      <blockquote className="mt-10 text-xl/8 font-semibold tracking-tight text-gray-900 sm:text-2xl/9">
                        <p>{testimonial.review}</p>
                      </blockquote>
                      <figcaption className="mt-10 flex items-center gap-x-6">
                        <div className="text-md/6">
                          <div className="font-semibold text-gray-900">- {testimonial.name}</div>
                        </div>
                      </figcaption>
                    </div>
                  </figure>
                </section>
              </Slide>
            ))}
          </Slider>
          <ButtonNext className="absolute right-0 top-1/2 z-10 -translate-y-1/2 transform">
            <ChevronRightIcon className="h-12 w-12 text-black" aria-hidden="true" />
          </ButtonNext>

          <div className="mx-auto text-center">
            <DotGroup />
          </div>
        </CarouselProvider>
      </div>
    </div>
  )
}
