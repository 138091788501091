import { Builder } from '@builder.io/react'
import { ProductList } from './ProductList'

Builder.registerComponent(ProductList, {
  name: 'Product List',
  inputs: [
    {
      name: 'products',
      type: 'list',
      subFields: [
        {
          name: 'product',
          type: `SwellProductHandle`,
        },
      ],
    },
    {
      name: 'categories',
      type: 'list',
      subFields: [
        {
          name: 'category',
          type: `SwellCategoryHandle`,
        },
      ],
    },
  ],
})
